import $http from '@/utils/http.js';

/**
 * 一级分类管理列表
 * @param data
 */
export function getList(data) {
    return $http.get('/assembly/index', data, 'loadingDiv');
}

export function download(data) {
    return $http.exports('/assembly/export', data, 'loadingDiv', '构件信息.xlsx');
}

export function changeStatus(data) {
    return $http.post('/assembly/enable', data, 'loadingDiv');
}

export function changeUpperLowerShelfStatus(data) {
    return $http.post('/assembly/shelf', data, 'loadingDiv');
}

export function del(data) {
    return $http.post('/assembly/delete', data, 'loadingDiv');
}

export function getsystemList(data) {
    return $http.get('/assembly/get_category', data, 'loadingDiv');
}

export function addsystem(data) {
    return $http.post('/assembly/save', data, 'loadingDiv', 'multipart/form-data');
}

export function getsystenDetail(data) {
    return $http.post('/assembly/get', data, 'loadingDiv');
}

export function systemexamine(data) {
    return $http.post('/assembly/examine', data, 'loadingDiv');
}

/**
 * 删除图片操作
 * @param data
 */
// export function deletePictureAction(data) {
//     return $http.post('/assembly/delete_picture', data, 'loadingDiv');
// }

/**
 * 编辑构件操作
 * @param data
 */
export function editsystem(data) {
    return $http.post('/assembly/edit_save', data, 'loadingDiv', 'multipart/form-data');
}
