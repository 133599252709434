<template>
    <div class="upload-edit-page">
        <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item class="title" label="基本信息"></el-form-item>
            <el-form-item label="选择品牌" prop="brandName">
                <el-select size="small" v-model="ruleForm.brandName" clearable placeholder="请选择" :disabled="
                    artifactsManageEditData.Status.Status === 4 || artifactsManageEditData.Status.Status === 6
                ">
                    <el-option v-for="(item, index) in brandStatus" :key="index" :label="item.BrandName"
                        :value="item.BrandID">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择分类" required>
                <el-row>
                    <el-col :span="6">
                        <el-form-item prop="system">
                            <el-select @change="getClass" v-model="ruleForm.system" placeholder="请选择系统" clearable
                                :disabled="
                                    artifactsManageEditData.Status.Status === 4 ||
                                    artifactsManageEditData.Status.Status === 6
                                ">
                                <el-option v-for="item in systemList" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="setup">
                            <el-select v-model="ruleForm.setup" placeholder="请选择体系" clearable :disabled="
                                artifactsManageEditData.Status.Status === 4 ||
                                artifactsManageEditData.Status.Status === 6
                            ">
                                <el-option v-for="item in setupList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-show="ruleForm.systemChange === 1 || ruleForm.setupChange === 1">
                        <div class="audit-prompt">修改项</div>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="构件名称" prop="artifactsName">
                <el-input size="small" v-model="ruleForm.artifactsName" :readonly="
                    artifactsManageEditData.Status.Status === 4 || artifactsManageEditData.Status.Status === 6
                "></el-input>
                <div class="audit-prompt" v-show="ruleForm.artifactsNameChange === 1">修改项</div>
            </el-form-item>
            <el-form-item label="材料">
                <el-input size="small" v-model="ruleForm.material" :readonly="
                    artifactsManageEditData.Status.Status === 4 || artifactsManageEditData.Status.Status === 6
                "></el-input>
                <div class="audit-prompt" v-show="ruleForm.materialChange === 1">修改项</div>
            </el-form-item>
            <el-form-item label="型号">
                <el-input size="small" v-model="ruleForm.model" :readonly="
                    artifactsManageEditData.Status.Status === 4 || artifactsManageEditData.Status.Status === 6
                "></el-input>
                <div class="audit-prompt" v-show="ruleForm.modelChange === 1">修改项</div>
            </el-form-item>
            <el-form-item label="材质">
                <el-input size="small" v-model="ruleForm.quality" :readonly="
                    artifactsManageEditData.Status.Status === 4 || artifactsManageEditData.Status.Status === 6
                "></el-input>
                <div class="audit-prompt" v-show="ruleForm.qualityChange === 1">修改项</div>
            </el-form-item>
            <el-form-item label="颜色" prop="color">
                <el-input size="small" v-model="ruleForm.color" :readonly="
                    artifactsManageEditData.Status.Status === 4 || artifactsManageEditData.Status.Status === 6
                "></el-input>
                <div class="audit-prompt" v-show="ruleForm.colorChange === 1">修改项</div>
            </el-form-item>
            <el-form-item label="置顶构件">
                <el-input size="small" v-model="ruleForm.topMember" placeholder="请输入排序序号"></el-input>
                <!-- :readonly=" -->
                <!-- artifactsManageEditData.Status.Status === 4 || artifactsManageEditData.Status.Status === 6 -->
                <!-- " -->
            </el-form-item>
            <el-form-item class="title" label="规格信息"></el-form-item>
            <el-form-item label="截面尺寸" prop="sectionSize">
                <el-input class="specsInput1" size="small" suffix-icon="el-icon-search" v-model="ruleForm.sectionSize"
                    :readonly="
                        artifactsManageEditData.Status.Status === 4 || artifactsManageEditData.Status.Status === 6
                    "></el-input>
                <div class="audit-prompt" v-show="ruleForm.sectionSizeChange === 1">修改项</div>
            </el-form-item>
            <el-form-item label="长度" prop="length">
                <el-input class="specsInput2" suffix-icon="el-icon-search" size="small" v-model="ruleForm.length"
                    :readonly="
                        artifactsManageEditData.Status.Status === 4 || artifactsManageEditData.Status.Status === 6
                    "></el-input>
                <div class="audit-prompt" v-show="ruleForm.lengthChange === 1">修改项</div>
            </el-form-item>
            <el-form-item label="构件主图">
                <el-upload action="" :limit="5" :auto-upload="false" :file-list="ruleForm.artifactsSubSrc"
                    list-type="picture-card" :on-change="handlePictureCardPreview" :on-exceed="handleExceed"
                    :on-remove="handleRemove" :disabled="
                        artifactsManageEditData.Status.Status === 4 || artifactsManageEditData.Status.Status === 6
                    ">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="picture-desc">
                    <p>尺寸为698*658px</p>
                    <p>格式为PNG/JPG，大小不超过10MB</p>
                </div>
                <div class="audit-prompt ml-0" v-show="ruleForm.artifactsSubSrcChange === 1">修改项</div>
            </el-form-item>
            <el-form-item label="构件副图">
                <el-upload class="avatar-uploader" action="" :show-file-list="false" list-type="picture-card"
                    :auto-upload="false" :on-change="handleAvatarSuccess" :disabled="
                        artifactsManageEditData.Status.Status === 4 || artifactsManageEditData.Status.Status === 6
                    ">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="picture-desc">
                    <p>尺寸为255*255px</p>
                    <p>格式为PNG/JPG，大小不超过10MB</p>
                </div>
                <div class="audit-prompt ml-0" v-show="ruleForm.artifactsMainSrcChange === 1">修改项</div>
            </el-form-item>
            <el-form-item class="title" label="构件记录" v-if="action === 'view' && artifactsLog.length > 0"></el-form-item>
            <el-form-item v-if="action === 'view' && artifactsLog.length > 0">
                <ul class="artifacts-record-list" :class="{ 'overflow-y-shaft': artifactsLog.length > 0 }">
                    <li class="artifacts-record-item" v-for="(item, index) in artifactsLog" :key="index">
                        <div>
                            <span>{{ item.brand_name }}</span>
                            <span>{{ item.operation }}</span>
                            <p style="display: inline-block; margin-left: 10px">
                                <span style="color: #3099ff" v-if="item.status === 0">{{
                                getBrandLogStatus(item.status)
                                }}</span>
                                <span style="color: #13ce66" v-else-if="item.status === 1">{{
                                getBrandLogStatus(item.status)
                                }}</span>
                                <span style="color: #ff6600" v-else>{{ getBrandLogStatus(item.status) }}</span>
                            </p>
                        </div>
                        <div>{{ item.update_time }}</div>
                    </li>
                </ul>
            </el-form-item>
            <el-form-item style="text-align: center" v-if="action == 'add'">
                <div class="confirm-upload" @click="handleConfirmUploadEditBtn('ruleForm')">确认上传</div>
            </el-form-item>
            <el-form-item style="margin-top: 50px; text-align: center" v-if="action == 'view'">
                <div style="margin-right: 15%"
                    v-if="artifactsManageEditData.Status.Status === 4 || artifactsManageEditData.Status.Status === 6">
                    <el-button type="primary" @click="handleThroughApply(1)">同意修改</el-button>
                    <el-button type="info" @click="handleThroughApply(2)">拒绝修改</el-button>
                </div>
                <div class="confirm-upload" @click="handleConfirmUploadEditBtn('ruleForm')" v-else>确认编辑</div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {
    getsystemList,
    addsystem,
    systemexamine,
    getsystenDetail,
    // deletePictureAction,
    editsystem
} from '@/common/api/operatsCenter/artifactsManage/index.js';
import { recordStatusList } from '@/utils/recordStatusList.js';

export default {
    name: 'comonpentUploadAddEdit',
    props: {
        action: {
            type: String,
            default: ''
        },
        brandStatus: {
            type: Array,
            default: () => { }
        },
        artifactsManageEditData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            // 构件记录状态列表
            artifactsLogStatusList: recordStatusList,
            // 构件记录
            artifactsLog: [],
            ruleForm: {
                // 选择品牌
                brandName: '',
                // 选择分类
                system: '', // 系统
                setup: '', // 体系
                // 构件名称
                artifactsName: '',
                // 材料
                material: '',
                // 型号
                model: '',
                // 材质
                quality: '',
                // 颜色
                color: '',
                // 置顶构件
                topMember: '',
                //截面尺寸
                sectionSize: '',
                // 长度
                length: '',
                // 构件主图
                artifactsMainSrc: '',
                // 构件副图
                artifactsSubSrc: [],
                // 修改项 0:未被修改项 1：被修改项
                systemChange: 0,
                setupChange: 0,
                artifactsNameChange: 0,
                materialChange: 0,
                modelChange: 0,
                qualityChange: 0,
                colorChange: 0,
                sectionSizeChange: 0,
                lengthChange: 0,
                artifactsMainSrcChange: 0,
                artifactsSubSrcChange: 0
            },
            rules: {
                brandName: [{ required: true, message: '请选择品牌', trigger: 'change' }],
                system: [{ required: true, message: '请选择系统', trigger: 'change' }],
                setup: [{ required: true, message: '请选择体系', trigger: 'change' }],
                artifactsName: [{ required: true, message: '构件名称不能为空', trigger: 'blur' }],
                color: [{ required: true, message: '颜色不能为空', trigger: 'blur' }],
                sectionSize: [{ required: true, message: '截面尺寸不能为空', trigger: 'blur' }],
                length: [{ required: true, message: '长度不能为空', trigger: 'blur' }]
            },
            // 系统列表
            systemList: [],
            // 体系列表
            setupList: [],
            imageUrl: '',
            // 构件主图删除图片
            deleteMainPictureArr: [],
            // 构件副图删除图片
            deleteSubPictureArr: []
        };
    },
    created() {
        this.getClass();
    },
    methods: {
        getBrandLogStatus(status) {
            return this.artifactsLogStatusList.find(val => val.id === status)?.name;
        },
        handleRemove(file, fileList) {
            if (this.action == 'view') {
                // let params = {
                //     ComponentID: this.artifactsManageEditData.ComponentID,
                //     PicturePath: file.url,
                //     UpdateId: this.artifactsManageEditData.update_id
                // };
                // deletePictureAction(params)
                //     .then(res => {
                //         let { code, msg } = res;
                //         if (code === 200) {
                //             this.$message({
                //                 type: 'success',
                //                 message: `${msg}`,
                //                 center: true
                //             });
                //             this.ruleForm.artifactsSubSrc = fileList;
                //         } else {
                //             this.$emit('closeDialog', false);
                //         }
                //     })
                //     .catch(err => {
                //         console.log(err);
                //     });
                this.deleteMainPictureArr.push(file.url);
            } else {
                this.ruleForm.artifactsSubSrc = fileList;
            }
        },
        handlePictureCardPreview(file, fileList) {
            const isType = file.type === 'image/jpeg' || 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('上传图片只能是 jpg/png 格式!');
                this.ruleForm.artifactsSubSrc.pop();
                return false;
            }
            if (!isLt10M) {
                this.$message.error('上传图片大小不能超过 10MB!');
                this.ruleForm.artifactsSubSrc.pop();
                return false;
            }
            let url = URL.createObjectURL(file.raw);
            new Promise(function (resolve, reject) {
                let width = 698;
                let height = 658;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width === width && img.height === height;
                    valid ? resolve() : reject();
                };
                img.src = url;
            }).then(
                () => {
                    this.ruleForm.artifactsSubSrc.push(file);
                    return file;
                },
                () => {
                    this.$message.error('上传图片尺寸只能是 698*658px !');
                    fileList.pop();
                    return false;
                }
            );
        },
        getClass(id, type = '') {
            let dt = {};
            if (id) {
                dt.CategoryPID = id;
            }
            getsystemList(dt).then(res => {
                if (id) {
                    this.setupList = res.ComponentCategory;
                } else {
                    this.systemList = res.ComponentCategory;
                }

                if (this.artifactsManageEditData.ComponentID && type != 1) {
                    let dt = {
                        ComponentID: this.artifactsManageEditData.ComponentID,
                        UpdateID: this.artifactsManageEditData.update_id
                    };
                    getsystenDetail(dt).then(res => {
                        if (res.code === 200) {
                            let a = res.Component;
                            this.ruleForm = {
                                brandName: parseInt(a.BrandID),
                                system: a.CpClassPID,
                                setup: a.CategoryID,
                                artifactsName: a.Name,
                                material: a.Materials,
                                model: a.ModelNumber,
                                quality: a.Texture,
                                color: a.Color,
                                // topMember: a.Sort,
                                topMember: a.Topping,
                                sectionSize: a.CrossProfile,
                                length: a.Height,
                                artifactsMainSrc: '',
                                artifactsSubSrc: [],
                                // 修改项 0:未被修改项 1：被修改项
                                systemChange: a.CpClassPID_change,
                                setupChange: a.CategoryID_change,
                                artifactsNameChange: a.Name_change,
                                materialChange: a.Materials_change,
                                modelChange: a.ModelNumber_change,
                                qualityChange: a.Texture_change,
                                colorChange: a.Color_change,
                                sectionSizeChange: a.CrossProfile_change,
                                lengthChange: a.Height_change,
                                artifactsMainSrcChange: a.MasterMap_change,
                                artifactsSubSrcChange: a.AuxiliaryChart_change
                            };
                            if (a.AuxiliaryChart) {
                                let arr = [];
                                for (var i = 0; i < a.AuxiliaryChart.length; i++) {
                                    let tmp = a.AuxiliaryChart[i];
                                    arr.push({ url: tmp });
                                }
                                this.ruleForm.artifactsSubSrc = arr;
                            }
                            this.imageUrl = a.MasterMap;
                            this.artifactsLog = res.ComponentLog.reverse();
                            this.getClass(a.CpClassPID, 1);
                        }
                    });
                }
            });
        },
        // 处理确认上传/编辑按钮
        handleConfirmUploadEditBtn(ruleForm) {
            this.$refs[ruleForm].validate(valid => {
                if (valid) {
                    let dt = new FormData();
                    if (this.artifactsManageEditData.ComponentID) {
                        dt.append('ComponentID', this.artifactsManageEditData.ComponentID);
                        dt.append('UpdateId', this.artifactsManageEditData.update_id);
                        for (var j = 0; j < this.deleteMainPictureArr.length; j++) {
                            dt.append('delete_picture[]', this.deleteMainPictureArr[j]);
                        }
                    }
                    dt.append('BrandID', this.ruleForm.brandName);
                    dt.append('CpClassPID', this.ruleForm.system);
                    dt.append('CpClassID', this.ruleForm.setup);
                    dt.append('Name', this.ruleForm.artifactsName);
                    dt.append('Materials', this.ruleForm.material);
                    dt.append('ModelNumber', this.ruleForm.model);
                    dt.append('Texture', this.ruleForm.quality);
                    dt.append('Color', this.ruleForm.color);
                    // dt.append('Sort', this.ruleForm.topMember);
                    dt.append('Topping', this.ruleForm.topMember);
                    dt.append('CrossProfile', this.ruleForm.sectionSize);
                    dt.append('Height', this.ruleForm.length);
                    dt.append('MasterMap', this.ruleForm.artifactsMainSrc.raw);
                    for (var i = 0; i < this.ruleForm.artifactsSubSrc.length; i++) {
                        dt.append('AuxiliaryChart[]', this.ruleForm.artifactsSubSrc[i].raw);
                    }
                    for (let [a, b] of dt.entries()) {
                        // console.log(a + '=>' + b);
                    }
                    if (this.action === 'view') {
                        editsystem(dt).then(res => {
                            if (res.code === 200) {
                                this.$message.success(res.msg);
                                this.$refs['ruleForm'].resetFields();
                                this.$emit('closeDialog', false);
                            }
                        });
                    } else {
                        addsystem(dt).then(res => {
                            if (res.code === 200) {
                                this.$message.success(res.msg);
                                this.$refs['ruleForm'].resetFields();
                                this.$emit('closeDialog', false);
                            }
                        });
                    }
                }
            });
        },
        // 处理通过申请/拒绝申请按钮
        handleThroughApply(type) {
            let actionName = type === 1 ? '通过' : '拒绝';
            let tmp = type === 1 ? 1 : 3;
            let dt = {
                ComponentID: this.artifactsManageEditData.ComponentID,
                Status: tmp,
                UpdateID: this.artifactsManageEditData.update_id,
                Topping: this.ruleForm.topMember
            };
            this.$confirm(`确认${actionName}此审核？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    systemexamine(dt).then(res => {
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: res.msg,
                                center: true
                            });
                            this.$emit('closeDialog', false);
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        handleAvatarSuccess(file) {
            const isType = file.type === 'image/jpeg' || 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('上传图片只能是 jpg/png 格式!');
                return false;
            }
            if (!isLt10M) {
                this.$message.error('上传图片大小不能超过 10MB!');
                return false;
            }
            let url = URL.createObjectURL(file.raw);
            new Promise(function (resolve, reject) {
                let width = 255;
                let height = 255;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width === width && img.height === height;
                    valid ? resolve() : reject();
                };
                img.src = url;
            }).then(
                () => {
                    this.imageUrl = file.url;
                    this.ruleForm.artifactsMainSrc = file;
                    return file;
                },
                () => {
                    this.$message.error('上传图片尺寸只能是 255*255px !');
                    return Promise.reject();
                }
            );
        },
        // 上传图片公用函数
        handleExceed(files, fileList) {
            this.$message.error(
                `当前限制选择5个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.upload-edit-page {
    padding: 0 20px;

    .title {
        :deep(.el-form-item__label) {
            font-size: 18px;
            color: #000;
        }
    }

    .audit-prompt {
        display: inline-block;
        width: 70px;
        margin-left: 10px;
        border: 1px solid #ffa800;
        background-color: #fff7e8;
        text-align: center;
        line-height: 30px;
        color: #ffa800;
        border-radius: 3px;
    }

    .ml-0 {
        margin-left: 0 !important;
    }

    .el-form-item:nth-child(15) {
        margin-bottom: 0 !important;
    }

    .el-input {
        width: 240px;
    }

    .specsInput1 :deep(.el-input__suffix-inner .el-input__icon:after) {
        content: '（长*宽*/mm）' !important;
        width: 110px;
        margin: 2px 0 0 -85px;
    }

    .specsInput2 :deep(.el-input__suffix-inner .el-input__icon:after) {
        content: '（mm）' !important;
        width: 52px;
        margin: 2px 0 0 -40px;
    }

    :deep(.el-icon-search:before) {
        display: none;
    }

    .avatar-uploader :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 148px;
        height: 148px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    :deep(.el-icon-plus:before) {
        position: relative;
        top: -16px;
    }

    .confirm-upload {
        width: 250px;
        margin-left: 22%;
        background: #1790ff;
        color: #fff;
        line-height: 40px;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
    }

    .overflow-y-shaft {
        height: 400px;
        overflow-y: scroll;
    }
}
</style>
