<template>
    <div class="artifacts-manage-page">
        <div class="artifacts-manage-header">
            <div class="header-left">
                <el-select size="small" v-model="artifactsManageForm.status" clearable placeholder="构件状态">
                    <el-option v-for="(item, index) in statusList" :key="index" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <el-select size="small" v-model="artifactsManageForm.brand" clearable placeholder="请选择">
                    <el-option
                        v-for="(item, index) in brandStatus"
                        :key="index"
                        :label="item.BrandName"
                        :value="item.BrandID"
                    >
                    </el-option>
                </el-select>
                <el-input
                    size="small"
                    v-model="artifactsManageForm.searchVal"
                    placeholder="请输入构件名称"
                    clearable
                ></el-input>
                <el-button size="small" type="primary" @click="init(1)">查询</el-button>
            </div>
            <div class="header-right">
                <el-button size="small" type="primary" @click="handleExport">导出</el-button>
                <span style="margin: 0 20px; top: 2px">|</span>
                <span style="top: 3px; margin-right: 20px">共{{ page.count }}条</span>
                <!-- <el-button size="small" type="primary" @click="handleUploadAddToView('add')">上传新构件</el-button> -->
            </div>
        </div>
        <div class="artifacts-manage-table">
            <el-table
                :header-cell-style="{ background: '#F2F2F2' }"
                :data="artifactsManageDataList"
                border
                align="center"
                style="width: 100%"
            >
                <el-table-column
                    width="60"
                    label="序号"
                    type="index"
                    :index="indexMethod"
                    align="center"
                ></el-table-column>
                <el-table-column prop="Number" label="编号" align="center" width="90"></el-table-column>
                <el-table-column prop="status" label="构件状态" align="center" width="120">
                    <template v-slot="scope">
                        <div
                            :class="[
                                scope.row.Status.Status === 2
                                    ? 'gray-color'
                                    : scope.row.Status.Status === 3
                                    ? 'red-color'
                                    : scope.row.Status.Status === 4 ||
                                      scope.row.Status.Status === 5 ||
                                      scope.row.Status.Status === 6
                                    ? 'blue-color'
                                    : ''
                            ]"
                        >
                            {{ scope.row.Status.StatusName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="Sort" label="置顶序号" align="center"></el-table-column>
                <el-table-column prop="Name" label="名称" align="center" width="150"></el-table-column>
                <el-table-column prop="Brand" label="所属品牌" align="center" width="180"></el-table-column>
                <el-table-column prop="Category" label="体系类别" align="center" width="150"></el-table-column>
                <el-table-column prop="Materials" label="材料" align="center" width="150"></el-table-column>
                <el-table-column prop="Color" label="颜色" align="center" width="150"></el-table-column>
                <el-table-column prop="CrossProfile" label="截面尺寸(长*宽/mm" align="center" width="150">
                    <template v-slot="scope">
                        <div>{{ scope.row.CrossProfile ? scope.row.CrossProfile : '/' }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="Length" label="长度(/mm）" align="center" width="150"></el-table-column>
                <el-table-column prop="cutSize" label="最小裁剪尺寸(/mm）" align="center" width="150">
                    <template v-slot="scope">
                        <div>{{ scope.row.cutSize ? scope.row.cutSize : '/' }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="PictureNumber" label="构件图片" align="center"></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="180px">
                    <template v-slot="scope">
                        <el-button @click="handleUploadAddToView('view', scope.row)" type="text" size="small"
                            >查看</el-button
                        >
                        <el-button
                            v-if="scope.row.Status.Status == 1 || scope.row.Status.Status == 2"
                            @click="handleChangeStatus(scope.row)"
                            type="text"
                            size="small"
                            >{{ scope.row.Status.Status === 1 ? '停用' : '启用' }}</el-button
                        >
                        <el-button
                            v-if="scope.row.IsOffShelf !== '' && scope.row.Status.Status === 1"
                            @click="handleUpperLowerShelf(scope.row)"
                            type="text"
                            size="small"
                            >{{ scope.row.IsOffShelf === 0 ? '下架' : '上架' }}</el-button
                        >
                        <el-button @click="handleDeleteBtn(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="text-align: right; margin-top: 30px" v-if="artifactsManageDataList.length > 0">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.page"
                :page-sizes="[10, 30, 40, 50]"
                :page-size="page.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page.count"
            >
            </el-pagination>
        </div>
        <!-- 上传、编辑新构件弹窗 -->
        <dialogFrame
            width="810px"
            :show="dialogUploadAddToViewArtifactsVisible"
            :title="action == 'add' ? '上传新构件' : '新构件申请'"
            @showDialog="showDialog"
            :footer="false"
        >
            <comonpentUploadAddEdit
                v-if="dialogUploadAddToViewArtifactsVisible"
                :action="action"
                :brandStatus="brandStatus1"
                :artifactsManageEditData="artifactsManageEditData"
                @closeDialog="closeDialog"
            ></comonpentUploadAddEdit>
        </dialogFrame>
    </div>
</template>

<script>
import dialogFrame from '@/components/common/dialog/dialog-frame.vue';
import comonpentUploadAddEdit from '@/views/operatsCenter/artifactsManage/components/comonpentUploadAddEdit.vue';
import {
    getList,
    download,
    changeStatus,
    changeUpperLowerShelfStatus,
    del
} from '@/common/api/operatsCenter/artifactsManage/index.js';

export default {
    components: {
        dialogFrame,
        comonpentUploadAddEdit
    },
    data() {
        return {
            artifactsManageForm: {
                // 状态
                status: '',
                // 品牌
                brand: '',
                searchVal: ''
            },
            page: {
                page: 1,
                pageSize: 10,
                count: 0
            },
            // 状态列表
            statusList: [
                {
                    id: '',
                    name: '全部'
                }
            ],
            // 品牌列表
            brandStatus: [
                {
                    BrandID: '',
                    BrandName: '全部'
                }
            ],
            brandStatus1: [],
            // 表格数据
            artifactsManageDataList: [],
            dialogUploadAddToViewArtifactsVisible: false,
            // 弹窗操作类型
            action: '',
            // 编辑弹窗数据
            artifactsManageEditData: {}
        };
    },
    created() {
        this.init();
    },
    watch: {
        dialogUploadAddToViewArtifactsVisible(val) {
            if (val == false) {
                this.init();
            }
        }
    },
    methods: {
        showDialog(val) {
            this.dialogUploadAddToViewArtifactsVisible = val;
        },
        closeDialog(val) {
            this.dialogUploadAddToViewArtifactsVisible = val;
            this.init();
        },
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },
        init(clear) {
            if(clear == 1){
                this.page.page = 1;
                this.page.pageSize = 10;
            }
            let dt = {
                Page: this.page.page,
                Len: this.page.pageSize,
                Status: this.artifactsManageForm.status,
                Materials: this.artifactsManageForm.searchVal,
                BrandID: this.artifactsManageForm.brand
            };
            getList(dt).then(res => {
                if (this.brandStatus.length == 1) {
                    this.brandStatus = this.brandStatus.concat(res.BrandInfo);
                }
                if (this.statusList.length == 1) {
                    for (let key in res.Status) {
                        this.statusList.push({
                            id: Number(key),
                            name: res.Status[key].name
                        });
                    }
                }
                this.brandStatus1 = res.BrandInfo;
                this.artifactsManageDataList = res.ComponentInfo;
                this.page.count = res.Count;
            });
        },
        // statusName(row) {
        //     return this.statusList.find(val => val.id === row.status)?.name;
        // },
        // 处理导出按钮
        handleExport() {
            let dt = {
                Status: this.artifactsManageForm.status,
                Materials: this.artifactsManageForm.searchVal,
                BrandID: this.artifactsManageForm.brand
            };
            download(dt).then(() => {
                this.$message({
                    type: 'success',
                    message: '下载成功!',
                    center: true
                });
            });
        },
        // 处理编辑按钮
        handleUploadAddToView(type, row) {
            if (row) {
                this.artifactsManageEditData = row;
            } else {
                this.artifactsManageEditData = {
                    Status: {
                        Status: ''
                    }
                };
            }
            this.action = type;
            this.dialogUploadAddToViewArtifactsVisible = true;
        },
        // 处理停用/启用按钮
        handleChangeStatus(row) {
            let actionName = row.Status.Status === 1 ? '停用' : '启用';
            let status = row.Status.Status === 1 ? 2 : 1;
            let dt = {
                ComponentID: row.ComponentID,
                Status: status,
                UpdateId: row.update_id
            };
            this.$confirm(`确认${actionName}此构件？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    changeStatus(dt).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            center: true
                        });
                        this.init();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理上架/下架按钮
        handleUpperLowerShelf(row) {
            let actionName = row.IsOffShelf === 0 ? '下架' : '上架';
            let status = row.IsOffShelf === 0 ? 1 : 0;
            let dt = {
                ComponentID: row.ComponentID,
                Status: status,
                UpdateId: row.update_id
            };
            this.$confirm(`确认${actionName}此构件？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    changeUpperLowerShelfStatus(dt).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            center: true
                        });
                        this.init();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理删除按钮
        handleDeleteBtn(row) {
            this.$confirm('确认删除该构件?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    let dt = {
                        ComponentID: row.ComponentID,
                        UpdateId: row.update_id
                    };
                    del(dt).then(res => {
                        let { code, msg } = res;
                        if (code === 200) {
                            this.$message({
                                type: 'success',
                                message: msg,
                                center: true
                            });
                            this.init();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.page.page = 1;
            this.init();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.init();
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.el-dialog) {
    border-radius: 5px;
}
.artifacts-manage-page {
    box-sizing: border-box;
    padding: 15px;
    background-color: #fff;
    .artifacts-manage-header {
        &::after {
            display: block;
            clear: both;
            content: '';
            height: 0;
            visibility: hidden;
        }
        .header-left {
            float: left;
            .el-select,
            .el-input {
                margin-right: 20px;
            }
            .el-input {
                width: 200px;
            }
        }
        .header-right {
            float: right;
            span {
                position: relative;
                font-size: 18px;
            }
        }
    }
    .artifacts-manage-table {
        margin-top: 30px;
        .red-color {
            color: red;
        }
        .blue-color {
            color: #409eff;
        }
        .gray-color {
            color: #b2b2b2;
        }
    }
}
</style>
